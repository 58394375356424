import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { MainComponent } from '../../../main/main.component';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { VolumService } from 'src/app/services/volumetricos/volum.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { VolumLecturasMesModel } from 'src/app/services/volumetricos/models/volum-lecturas-mes.model';
import { VolumLectruraModel as VolumLecturaModel } from 'src/app/services/volumetricos/models/volum-lectura.model';

import { MenuComponent } from '../../../main/menu/menu.component';
import { TotalVolumComponent } from '../total-volum/total-volum.component';
import { ResumenVolumComponent } from '../resumen-volum/resumen-volum.component';
import { LecVolumGraphComponent } from '../../../graphics/volum/lec-volum-graph/lec-volum-graph.component';
import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../../periodo-select/periodo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-volum',
  templateUrl: './listado-volum.component.html',
  styleUrls: ['./listado-volum.component.css']
})
export class ListadoVolumComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: ListadoVolumComponent;

  private static instance: ListadoVolumComponent = null;
  private componentRef = null;
  public environment = environment;
  public lecturas: VolumLecturaModel[] = [];
  private lecturaSelec: VolumLecturaModel;
  public volumConElemento = new Map<string, string>();
  public volumSinElemento = new Map<string, string>();
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  mapWidth: number;
  mapHeight: number;

  // Contadores de lecturas y envíos
  public lecturasEnHora = 0;
  public lecturasAtrasadas = 0;
  public lecturasFueraHora = 0;
  public enviosEnHora = 0;
  public enviosAtrasados = 0;
  public enviosFueraHora = 0;
  public lecturasSinElemento = 0;
  public lecturasErroneas = 0;
  public bateriaBaja = new Map<string, string>();
  public bateriaMuyBaja = new Map<string, string>();
  public temperaturaAlta = new Map<string, string>();
  public temperaturaMuyAlta = new Map<string, string>();
  public lecturasVolum = new Map<string, VolumLecturasMesModel>();
  public resumenVisible = false;
  public totalVisible = false;

  // Para la búsqueda de elementos
  private searchText = '';
  private timerSearch = null;

  // Definiciones
  public _15_MIN = 15 * 60 * 1000; // Quince minutos (en milisegundos)
  public _30_MIN = 30 * 60 * 1000; // Treinta minutos (en milisegundos)
  public BAT_BAJA = 50;
  public BAT_MUY_BAJA = 25;
  public TEMP_ALTA = 65;
  public TEMP_MUY_ALTA = 85;
  private LECT_ERRONEA = 999;
  private ORANGE = '#ffb266';
  private RED = '#ff6666';

  // Variables para generar el listado
  public dataSource;
  public dataAdapter;

  columns: any[] = [];



  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  public static getInstance(): ListadoVolumComponent {
    return ListadoVolumComponent.instance;
  }

  constructor(
    private ssoService: SsoService,
    private volumService: VolumService,
    private auditoriaService: AuditoriaService,) {
    super();
    ListadoVolumComponent.instance = this;
    ListadoVolumComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    setTimeout(() => {
      this.initGrid();
    }, 1000);

  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Listado_volumetricos'));
    this.resizeColumns(this.grid);
    const t = setTimeout(() => {
      clearTimeout(t);
      this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Todos') + '</span></div>'
      });
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Con_elemento') + '</span></div>'
      });
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Sin_elemento') + '</span></div>'
      });
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Bateria_baja') + '</span></div>'
      });
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Bateria_muy_baja') + '</span></div>'
      });
      this.header.cbVolumetricos.addItem({
        html: '<div style="height: 20px; float: left;">' +
          '<span style="float: left; font-size: 13px;">' + this.translate('Lecturas_erroneas') + '</span></div>'
      });
      this.header.cbVolumetricos.selectIndex(0);
      this.initGridHeader();
    }, 0);
    // Recupero las configuraciones
    const volumConfig = await this.volumService.getConfiguraciones();
    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(false);
    }
  }

  initGrid(): void {

    // Preparo las columnas del grid
    this.columns = [
      {
        text: this.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          if (value !== this.translate('Sin_elemento')) {
            return defaulHhtml;
          }
          return '<div style="text-align: center; margin-top:3px; background-color: #ff6666;">' + value + '</div>';
        },
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: center; margin-left: 4px;">' +
              NumberUtils.format(aggregates["Total"], 0) + ' </div>';
          }
          return renderstring;
        }
      },
      {
        text: this.translate('Hora_inicio'), columntype: 'date', filtertype: 'textbox',
        datafield: 'horaInicio', cellsformat: 'HH:mm:ss', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          if (value) {
            return defaulHhtml;
          }
          return '<div style="text-align: center; margin-top:3px; background-color: #ff6666;"> x </div>';
        }
      },
      {
        text: this.translate('Frecuencia'), columntype: 'textbox', filtertype: 'textbox',
        datafield: 'frecuencia', align: 'right', cellsalign: 'right',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          if (value && Number.parseInt(value) > 0) {
            defaulHhtml = '<div style="text-align: right; margin-top:3px; margin-right: 4px;">' + NumberUtils.format(value, 0) + '</div>';
            return defaulHhtml;
          }
          return '<div style="text-align: center; margin-top:3px; background-color: #ff6666;"> x </div>';
        }
      },
      {
        text: this.translate('Fecha_lectura'), columntype: 'date', filtertype: 'date',
        datafield: 'fechaLectura', cellsformat: 'dd/MM/yy HH:mm:ss', align: 'center',
        cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          return '<div style="text-align: center; margin-top:3px; background-color: ' + rowData.colorLec + ';">' +
            DateUtils.formatDateTimeShort(value, true) + '</div>';
        }
      },
      {
        text: this.translate('Fecha_recepcion'), columntype: 'date', filtertype: 'date',
        datafield: 'fechaRecepcion', cellsformat: 'dd/MM/yy HH:mm:ss', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          return '<div style="text-align: center; margin-top:3px; background-color: ' + rowData.colorRec + ';">' +
            DateUtils.formatDateTimeShort(value, true) + '</div>';
        }
      },
      {
        text: this.translate('Lectura'), columntype: 'textbox', filtertype: 'textbox',
        datafield: 'lectura', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          if (rowData.lectura !== this.LECT_ERRONEA) {
            return defaulHhtml;
          }
          return '<div style="text-align: right; margin-top:3px; margin-right: 4px;">' + NumberUtils.format(value, 0) + '</div>';
        }
      },
      {
        text: this.translate('Porcentaje'), columntype: 'textbox', filtertype: 'textbox',
        datafield: 'porcentaje', align: 'right', cellsalign: 'right', cellsrenderer: this.numberrenderer
      },
      {
        text: this.translate('Bateria'), columntype: 'textbox', filtertype: 'textbox',
        datafield: 'bateria', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          // Compruebo si el nivel de batería es bajo
          if (value < this.BAT_BAJA) {
            if (value > this.BAT_MUY_BAJA) {
              return '<div style="text-align: right; margin-top:3px; margin-right: 4px; background-color: #ffb266;">' + NumberUtils.format(value, 0) + '</div>';
            }
            return '<div style="text-align: right; margin-top:3px; margin-right: 4px; background-color: #ff6666;">' + NumberUtils.format(value, 0) + '</div>';
          }
          return defaulHhtml;
        }
      },
      {
        text: this.translate('Temperatura'), columntype: 'textbox', filtertype: 'textbox',
        datafield: 'temperatura', align: 'center', cellsalign: 'center',
        cellsrenderer: (row: number, columnField: string, value: any,
          defaulHhtml: string, columnProperties: any, rowData: any) => {
          // Compruebo si la temperatura es elevada
          if (value > this.TEMP_ALTA) {
            if (value < this.TEMP_MUY_ALTA) {
              return '<div style="style="text-align: right; margin-top:3px; margin-right: 4px; background-color: #ffb266;">' + NumberUtils.format(value, 0) + '</div>';
            }
            return '<div style="text-align: right; margin-top:3px; margin-right: 4px; background-color: #ff6666;">' + NumberUtils.format(value, 0) + '</div>';
          }
          return defaulHhtml;
        }
      },
      {
        text: this.translate('Volumetrico') + ' IMEI', columntype: 'textbox', filtertype: 'textbox',
        datafield: 'imei', width: 148
      },
      {
        text: 'ColorLec', columntype: 'textbox', datafield: 'colorLec', width: 0, hidden: true
      },
      {
        text: 'ColorRec', columntype: 'textbox', datafield: 'colorRec', width: 0, hidden: true
      },
      {
        text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1
      }
    ];

  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.timerSearch === null) {
      clearInterval(this.timerSearch);
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoVolumComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return MainComponent.translate(text);
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined) {
      // Añado el control de búsqueda a la cabecera
      const toolbarContainer = document.createElement('div');
      toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 2px';
      // Creo el botón para expandir todos los registros del grid
      const btnExpandir: any = document.createElement('div');
      btnExpandir.id = 'btnExpandir';
      btnExpandir.style.cssText = 'float: left; height: 30px; width: 105px; padding-left: 3px; padding-top: 2px;';
      btnExpandir.innerHTML = `
          <button type="submit" style="width: 100px; height: 25px; align-items: center;">
          <div style="float: right; padding-top: 2px;">`+ MainComponent.getInstance().translate('Expandir') + `</div>
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/expandir.png" /></button>
        `;
      toolbarContainer.appendChild(btnExpandir);
      // Creo el botón para contraer todos los registros del grid
      const btnContraer: any = document.createElement('div');
      btnContraer.id = 'btnContraer';
      btnContraer.style.cssText = 'float: left; height: 30px; width: 105px; padding-left: 3px; padding-top: 2px;';
      btnContraer.innerHTML = `
          <button type="submit" style="width: 100px; height: 25px; align-items: center;">
          <div style="float: right; padding-top: 2px;">`+ MainComponent.getInstance().translate('Contraer') + `</div>
          <img style="float: left; height: 18px; width: 18px;" src="../assets/images/contraer.png" /></button>
        `;
      toolbarContainer.appendChild(btnContraer);
      statusbar[0].appendChild(toolbarContainer);
    }
  }

  // Inicializa los controles de la cabecera del grid
  initGridHeader(): void {
    const searchControl = document.getElementById('searchControlVolum');
    if (searchControl) {
      searchControl.addEventListener('input', (event: any) => {
        this.searchText = event.target.value.toUpperCase();
      });
      let lastSearch = '';
      // Cada segundo compruebo si se ha filtrado la información
      this.timerSearch = setInterval(() => {
        if (this.searchText !== lastSearch) {
          lastSearch = this.searchText;
          this.lecturas.forEach(lec => {
            if (lec.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1 ||
              lec.Imei.toUpperCase().indexOf(this.searchText) > -1) {
              lec.selec = 'selec';
            } else {
              lec.selec = '';
            }
          });
          // Compruebo si ya he creado el filtro "selec" anteriormente
          const filters = this.grid.getfilterinformation();
          if (filters.find(s => s.datafield === 'selec') === undefined) {
            const filtergroup = new jqx.filter();
            filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
            this.grid.addfilter('selec', filtergroup);
          }
          this.grid.applyfilters();
          this.grid.updatebounddata('data');

          this.resizeColumns(this.grid);
        }
      }, 1000);
    }
    const btnExpandir = document.getElementById('btnExpandir');
    btnExpandir.addEventListener('click', (event: any) => {
      this.grid.expandallgroups();
    });
    const btnContraer = document.getElementById('btnContraer');
    btnContraer.addEventListener('click', (event: any) => {
      this.grid.collapseallgroups();
    });
  }

  async onAceptar() {
    this.volumConElemento.clear();
    this.volumSinElemento.clear();
    // Pongo a 0 los acumulados
    this.lecturasEnHora = 0;
    this.lecturasAtrasadas = 0;
    this.lecturasFueraHora = 0;
    this.enviosEnHora = 0;
    this.enviosAtrasados = 0;
    this.enviosFueraHora = 0;
    this.lecturasSinElemento = 0;
    this.lecturasErroneas = 0;
    this.bateriaBaja.clear();
    this.bateriaMuyBaja.clear();
    this.temperaturaAlta.clear();
    this.temperaturaMuyAlta.clear();
    this.lecturasVolum.clear();
    this.lecturas = [];
    this.header.searchInput['nativeElement'].value = '';
    if (this.header.cbVolumetricos.getSelectedIndex() === 0 ||
      this.header.cbVolumetricos.getSelectedIndex() === 1 ||
      this.header.cbVolumetricos.getSelectedIndex() === 3 ||
      this.header.cbVolumetricos.getSelectedIndex() === 4 ||
      this.header.cbVolumetricos.getSelectedIndex() === 5) {
      const lecturasCon = await this.volumService.getLecturas(
        this.ssoService.getTicket().Empresa.IdGestion,
        this.header.periodoSelect.desde, this.header.periodoSelect.hasta);
      if (lecturasCon) {
        lecturasCon.forEach(lec => {
          let ignore = false;
          if (this.header.cbVolumetricos.getSelectedIndex() === 3 && lec.Bateria > this.BAT_BAJA) {
            ignore = true;
          }
          if (this.header.cbVolumetricos.getSelectedIndex() === 4 && lec.Bateria > this.BAT_MUY_BAJA) {
            ignore = true;
          }
          if (this.header.cbVolumetricos.getSelectedIndex() === 5 && lec.Lectura !== this.LECT_ERRONEA) {
            ignore = true;
          }
          if (!ignore) {
            this.lecturas.push(lec);
            // Cuento el número de volumétricos con elemento
            this.volumConElemento.set(lec.Imei, lec.Imei);
            // Sumo la lectura a las lecturas por día del volumétrico
            let lecVolum = this.lecturasVolum.get(lec.Imei);
            if (!lecVolum) {
              lecVolum = new VolumLecturasMesModel(lec.Imei, lec.Elemento);
            }
            lecVolum.sumaLecturaDia(lec.FechaLectura.getDate());
            this.lecturasVolum.set(lec.Imei, lecVolum);
          }
        });
      }
    }
    if (this.header.cbVolumetricos.getSelectedIndex() === 0 ||
      this.header.cbVolumetricos.getSelectedIndex() === 2 ||
      this.header.cbVolumetricos.getSelectedIndex() === 3 ||
      this.header.cbVolumetricos.getSelectedIndex() === 4 ||
      this.header.cbVolumetricos.getSelectedIndex() === 5) {
      const lecturasSin = await this.volumService.getLecturasSinElemento(
        this.header.periodoSelect.desde, this.header.periodoSelect.hasta);
      if (lecturasSin) {
        // Añado las lecturas de volumétricos sin elemento a las anteriores
        lecturasSin.forEach(lec => {
          let ignore = false;
          if (this.header.cbVolumetricos.getSelectedIndex() === 3 && lec.Bateria > this.BAT_BAJA) {
            ignore = true;
          }
          if (this.header.cbVolumetricos.getSelectedIndex() === 4 && lec.Bateria > this.BAT_MUY_BAJA) {
            ignore = true;
          }
          if (this.header.cbVolumetricos.getSelectedIndex() === 5 && lec.Lectura !== this.LECT_ERRONEA) {
            ignore = true;
          }
          if (!ignore) {
            this.lecturas.push(lec);
            // Cuanto el número de volumétricos sin elemento
            this.volumSinElemento.set(lec.Imei, lec.Imei);
          }
        });
      }
    }
    if (this.lecturas) {
      this.lecturas.forEach(lec => {
        this.procesaLectura(lec);
        this.procesaEnvio(lec);
      });
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'elemento', map: 'Elemento>Nombre', type: 'string' },
          { name: 'horaInicio', map: 'Config>HoraInicio', type: 'date' },
          { name: 'frecuencia', map: 'Config>FrecuenciaLectura', type: 'int' },
          { name: 'fechaLectura', map: 'FechaLectura', type: 'date' },
          { name: 'fechaRecepcion', map: 'Fecha', type: 'date' },
          { name: 'lectura', map: 'Lectura', type: 'float' },
          { name: 'porcentaje', map: 'Porcentaje', type: 'float' },
          { name: 'bateria', map: 'Bateria', type: 'float' },
          { name: 'temperatura', map: 'Temperatura', type: 'float' },
          { name: 'imei', map: 'Imei', type: 'string' },
          { name: 'colorLec', map: 'colorLec', type: 'string' },
          { name: 'selec', map: 'selec', type: 'string' }
        ],
        localdata: this.lecturas,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    }
    const t = setTimeout(() => {
      clearTimeout(t);
      this.grid.addgroup('elemento');
      this.grid.expandallgroups();
    }, 1000);
    this.onBuscar()

    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(false);
    }
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.lecturas.forEach(lect => {
      if (
        lect?.Elemento?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Config?.HoraInicio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Config?.FrecuenciaLectura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.FechaLectura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Fecha + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Lectura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Porcentaje + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Bateria + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (lect?.Temperatura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        lect?.Imei?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        lect?.colorLec?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        lect['selec'] = 'selec';
      } else {
        lect['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    this.onAceptar();
  }

  procesaLectura(lectura: VolumLecturaModel) {
    if (!lectura.Elemento || lectura.Elemento.Nombre === MainComponent.translate('Sin_elemento')) {
      this.lecturasSinElemento++;
    } else {
      // Solo me interesan las lecturas erroneas de los volumétricos con elemento
      if (lectura.Lectura === this.LECT_ERRONEA) {
        this.lecturasErroneas++;
      }
    }
    if (lectura.Bateria < this.BAT_BAJA) {
      if (lectura.Bateria > this.BAT_MUY_BAJA) {
        this.bateriaBaja.set(lectura.Imei, lectura.Imei);
      } else {
        this.bateriaMuyBaja.set(lectura.Imei, lectura.Imei);
      }
    }
    if (lectura.Temperatura > this.TEMP_ALTA) {
      if (lectura.Temperatura < this.TEMP_MUY_ALTA) {
        this.temperaturaAlta.set(lectura.Imei, lectura.Imei);
      } else {
        this.temperaturaMuyAlta.set(lectura.Imei, lectura.Imei);
      }
    }
    if (lectura.Config && lectura.Config.HoraInicio &&
      lectura.Config.FrecuenciaLectura && lectura.Config.NumeroMedidas) {
      // Calculo la fecha inicial de lectura
      const fechaIni = new Date(lectura.FechaLectura.getFullYear(), lectura.FechaLectura.getMonth(),
        lectura.FechaLectura.getDate(), lectura.Config.HoraInicio.getHours(),
        lectura.Config.HoraInicio.getMinutes());
      // Si la fecha de lectura es anterior a la incial retrocedo un día
      if (lectura.FechaLectura < fechaIni) {
        fechaIni.setDate(fechaIni.getDate() - 1);
      }
      const fechaFin = new Date(lectura.FechaLectura);
      while (fechaIni <= fechaFin) {
        if (lectura.FechaLectura.getTime() - fechaIni.getTime() < this._30_MIN) {
          if (lectura.FechaLectura.getTime() - fechaIni.getTime() < this._15_MIN) {
            this.lecturasEnHora++;
            lectura.colorLec = undefined;
            return;
          }
          this.lecturasAtrasadas++;
          lectura.colorLec = this.ORANGE;
          return;
        }
        fechaIni.setTime(fechaIni.getTime() + (lectura.Config.FrecuenciaLectura * 60 * 1000));
      }
      this.lecturasFueraHora++;
      lectura.colorLec = this.RED;
    }
  }

  procesaEnvio(lectura: VolumLecturaModel) {
    if (lectura.Config && lectura.Config.HoraInicio &&
      lectura.Config.FrecuenciaLectura && lectura.Config.NumeroMedidas) {
      // Calculo la fecha inicial de lectura
      const fechaIni = new Date(lectura.FechaLectura.getFullYear(), lectura.FechaLectura.getMonth(),
        lectura.FechaLectura.getDate(), lectura.Config.HoraInicio.getHours(),
        lectura.Config.HoraInicio.getMinutes());
      // Si la fecha de lectura es anterior a la incial retrocedo un día
      if (lectura.FechaLectura < fechaIni) {
        fechaIni.setDate(fechaIni.getDate() - 1);
      }
      // Calculo la fecha de envío inicial correcta
      // Esto sería si la fecha incial fuese la fecha de lectura, no la de envío
      // const fechaEnvio = new Date(fechaIni.getTime() +
      //   ((lectura.Config.FrecuenciaLectura * 60 * 1000) * (lectura.Config.NumeroMedidas - 1)));
      // La fecha de inicio de envíos es la misma que la de lectura
      const fechaEnvio = new Date(fechaIni);
      // Calculo la fecha de envío para el paquete al que pertenece la lectura
      while ((lectura.FechaLectura.getTime() - fechaEnvio.getTime()) > this._30_MIN) {
        fechaEnvio.setTime(fechaEnvio.getTime() +
          ((lectura.Config.FrecuenciaLectura * 60 * 1000) * lectura.Config.NumeroMedidas));
      }
      // Calculo la fecha incial de lectura del paquete de envío
      const fechaIniLectura = new Date(fechaEnvio.getTime() -
        ((lectura.Config.FrecuenciaLectura * 60 * 1000) * (lectura.Config.NumeroMedidas - 1)));
      if (lectura.Fecha.getTime() >= fechaEnvio.getTime() &&
        lectura.Fecha.getTime() - fechaEnvio.getTime() < this._30_MIN &&
        lectura.FechaLectura.getTime() >= fechaIniLectura.getTime()) {
        if (lectura.Fecha.getTime() - fechaEnvio.getTime() < this._15_MIN) {
          this.enviosEnHora++;
          lectura.colorRec = undefined;
          return;
        }
        this.enviosAtrasados++;
        lectura.colorRec = this.ORANGE;
        return;
      }
      this.enviosFueraHora++;
      lectura.colorRec = this.RED;
    }
  }

  onBindingComplete(): void {
    this.grid.expandallgroups();
  }

  onRowClick(event: any) {
    this.lecturaSelec = this.lecturas[event.args.rowindex];
  }

  onCentrar(event: any) {
    if (this.lecturaSelec && this.lecturaSelec.Elemento && this.lecturaSelec.Elemento.Marker) {
      this.form.collapse();
      const map = MainComponent.getActiveMap();
      map.setCenter(this.lecturaSelec.Elemento.Marker.position);
      map.setZoom(18);
      this.lecturaSelec.Elemento.Marker.animate(2850);
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_registro', 3000);
    }
  }

  onResumen(event: any) {
    if (this.lecturas.length < 1) {
      MainComponent.showWarning('ATENCION', 'Genere_listado', 3000);
      return;
    }
    this.resumenVisible = true;
    const component = MenuComponent.getInstance().menuContainer.createComponent(ResumenVolumComponent);
    component.instance.init({
      parentComponent: this.form,
      component: component,
      fechaIni: this.header.periodoSelect.getFechaIni(),
      fechaFin: this.header.periodoSelect.getFechaFin(),
      volumSinElemento: this.volumSinElemento.size,
      volumConElemento: this.volumConElemento.size,
      lecturasTotales: this.lecturas.length,
      lecturasEnHora: this.lecturasEnHora,
      lecturasAtrasadas: this.lecturasAtrasadas,
      lecturasFueraHora: this.lecturasFueraHora,
      enviosEnHora: this.enviosEnHora,
      enviosAtrasados: this.enviosAtrasados,
      enviosFueraHora: this.enviosFueraHora,
      lecturasSinElemento: this.lecturasSinElemento,
      lecturasErroneas: this.lecturasErroneas,
      bateriaBaja: this.bateriaBaja.size,
      bateriaMuyBaja: this.bateriaMuyBaja.size,
      temperaturaAlta: this.temperaturaAlta.size,
      temperaturaMuyAlta: this.temperaturaMuyAlta.size,
      lecturasVolumMes: this.lecturasVolum
    });
    this.form.collapse();
  }

  onGrafica(event: any) {
    if (this.lecturaSelec) {
      const component = MenuComponent.getInstance().menuContainer.createComponent(LecVolumGraphComponent);
      // Me quedo con las lecturas del volumétrico seleccionado
      const lecturasSelec = [];
      this.lecturas.forEach(lec => {
        if (lec.Imei === this.lecturaSelec.Imei) {
          lecturasSelec.push(lec);
        }
      });
      component.instance.init({
        parentComponent: this.form,
        component: component,
        fechaIni: this.header.periodoSelect.getFechaIni(),
        fechaFin: this.header.periodoSelect.getFechaFin(),
        elemento: this.lecturaSelec.Elemento,
        lecturas: lecturasSelec
      });
      this.form.collapse();
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_registro', 3000);
    }
  }

  onTotal(event: any) {
    this.totalVisible = true;
    const component = MenuComponent.getInstance().menuContainer.createComponent(TotalVolumComponent);
    component.instance.init({
      parentComponent: this.form,
      component: component
    });
    this.form.collapse();
  }

  onExportar() {
    // const json = JSON.parse(JSON.stringify(this.grid.getrows()));
    // json.forEach(elem => { // Aqui se pueden manipular los datos antes de exportar
    //   elem.Cubos = Number.parseFloat(elem.Cubos.replace(',', '.'));
    // });

    const json = JSON.parse(JSON.stringify(this.grid.getrows()));
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Listado_volumetricos') + '.xlsx');
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_VOLUMETRICOS;
    this.auditoriaService.addAuditoria(this.auditoria);
  }

}
