import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { MainComponent } from '../main.component';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { IaElementsComponent, IaGridComponent } from 'movisat-ia';

import { jqxMenuComponent } from 'jqwidgets-ng/jqxmenu';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';

import { BdtService } from 'src/app/services/bdt/bdt.service';
import { MenuService } from 'src/app/services/menu/menu.service';
import { ConfigService } from 'src/app/services/config/config.service';

import { CasaModel } from 'src/app/services/geographics/casa.model';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';

import { KmlComponent } from '../../kml/kml.component';
import { NotasComponent } from '../../notas/notas.component';
import { ZonesComponent } from '../../zones/zones.component';
import { CasaComponent } from '../../maps-tools/casa/casa.component';
import { CalendarComponent } from '../../calendar/calendar.component';
import { MarcoComponent } from '../../maps-tools/marco/marco.component';
import { AmbitoComponent } from '../../maps-tools/ambito/ambito.component';
import { MeasureComponent } from '../../maps-tools/measure/measure.component';
import { SubflotasComponent } from '../../flota/subflotas/subflotas.component';
import { CuadriculasComponent } from '../../cuadriculas/cuadriculas.component';
import { AccionesComponent } from '../../auditoria/acciones/acciones.component';
import { InformesComponent } from '../../auditoria/informes/informes.component';
import { AlarmsgeoComponent } from '../../alarms/alarmsgeo/alarmsgeo.component';
import { ItinerariComponent } from '../../maps-tools/itinerari/itinerari.component';
import { MovilesCercaComponent } from '../../flota/moviles-cerca/moviles-cerca.component';
import { CartoSelectComponent } from '../../maps-tools/carto-select/carto-select.component';
import { AssociateLockComponent } from '../../calendar/associate-lock/associate-lock.component';
import { ListadoVolumComponent } from '../../reports/volum/listado-volum/listado-volum.component';
import { ElementsSelectComponent } from '../../elements/elements-select/elements-select.component';
import { MovilesCatalogComponent } from '../../resources/moviles-catalog/moviles-catalog.component';
import { GridIncidencesComponent } from '../../incidences/grid-incidences/grid-incidences.component';
import { ListadoSensorComponent } from '../../reports/sensor/listado-sensor/listado-sensor.component';
import { ElementsCatalogComponent } from '../../elements/elements-catalog/elements-catalog.component';
import { TarjetasCiudadanosComponent } from '../../tarjetas-ciudadanos/tarjetas-ciudadanos.component';
import { ListadoAlarmasComponent } from '../../reports/alarmas/listado-alarmas/listado-alarmas.component';
import { ListadoTareasComponent } from '../../administrador/tareas/listado-tareas/listado-tareas.component';
import { ListadoMovilidadComponent } from '../../reports/movilidad/listado-movilidad/listado-movilidad.component';
import { ListadoCerraduraComponent } from '../../reports/cerraduras/listado-cerradura/listado-cerradura.component';
import { ConfiguracionCerradurasComponent } from '../../configuracion-cerraduras/configuracion-cerraduras.component';

enum App {
  ECOEVOLUTION = 3,
  ECOEVOLUTION_DEV = 5,
  ECOSATLITE = 11,
  GESCONSAT = 14
}

const MIN_DISPLAY_WIDTH = 1450;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, AfterViewInit {
  @ViewChild('menu') menu: jqxMenuComponent;
  @ViewChild('menuContainer', { read: ViewContainerRef }) menuContainer;
  @ViewChild('loader') loader: jqxLoaderComponent;

  private static instance: MenuComponent;
  public environment = environment;
  public minimized = false;
  private manualTongle = false;
  private menuConTextos = true;
  public options: any[] = [];
  private menuSave: any[] = [];
  private menuOptions: any = [];

  constructor(
    private menuService: MenuService,
    private bdtService: BdtService,
    private configService: ConfigService) {
    MenuComponent.instance = this;
  }

  public static getInstance() {
    return MenuComponent.instance;
  }

  ngOnInit(): void {
  }


  async ngAfterViewInit(): Promise<void> {
    if (window.innerWidth < MIN_DISPLAY_WIDTH) {
      this.minimized = true;
      setTimeout(() => {
        this.tongleMenu(false);
      }, 500);
    }
    // Cuando se redimensiona la ventana del navegador
    window.addEventListener('resize', () => {
      // Si se estrecha mucho la ventana minimizo el menú automáticamente
      if (!this.minimized && window.innerWidth < MIN_DISPLAY_WIDTH) {
        setTimeout(() => {
          this.ponTextosMenu(false);
        }, 500);
        if (!this.manualTongle) {
          setTimeout(() => {
            this.tongleMenu(true);
          }, 500);
          return
        }
      } else {
        // Si se ensancha la ventana lo suficiente y no se ha minimizado manualmente el menú lo maximizo
        if (!this.minimized && window.innerWidth >= MIN_DISPLAY_WIDTH) {
          this.ponTextosMenu(true);
        }
      }
      const menuContainer = document.getElementById('menu-container');
      const centerContainer = document.getElementById('center-container');
      if (this.minimized) {
        menuContainer.style.width = '0px';
        centerContainer.style.width = (window.innerWidth - 0) + 'px';
      } else {
        if (window.innerWidth >= MIN_DISPLAY_WIDTH) {
          menuContainer.style.width = '170px';
          centerContainer.style.width = (window.innerWidth - 171) + 'px';
        } else {
          menuContainer.style.width = '145px';
          centerContainer.style.width = (window.innerWidth - 146) + 'px';
        }
      }

      if (window.screen.width < 768) {
        this.menu.widgetObject.getInstance().items.forEach(element => {
          if (element.level > 1) {
            element.element.parentElement.parentElement.parentElement.style.width = '150px';
            element.element.parentElement.parentElement.style.width = '150px';
            element.element.parentElement.style.width = '150px';
          }
        });

        this.menuOptions.forEach(element => {
          if (element.parentid > 0) {
            this.menu.setItemOpenDirection(element.id, 'center', 'center');
          }
        });
      } else {
        this.menu.widgetObject.getInstance().items.forEach(element => {
          if (element.level > 1) {
            element.element.parentElement.parentElement.parentElement.style.width = '230px';
            element.element.parentElement.parentElement.style.width = '230px';
            element.element.parentElement.style.width = '230px';
          }
        });

        this.menuOptions.forEach(element => {
          if (element.parentid > 0) {
            this.menu.setItemOpenDirection(element.id, 'right', 'down');
          }
        });
      }
    });
    // Genero el evento para que se visualice el menú
    window.dispatchEvent(new Event('resize'));
    // Cargo el menú de la aplicación
    this.loadMenu();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  loadMenu() {
    const t = setTimeout(async () => {
      clearTimeout(t);
      // Cargo el menú
      const menuData = await this.menuService.getMenu(
        MainComponent.getInstance().ssoTicket.Aplicacion.Id,
        MainComponent.getInstance().ssoTicket.Empresa.Id
      );
      const user = MainComponent.getInstance().ssoTicket.Usuario;
      const menuOptions: any = [];
      if (menuData && menuData.Opciones) {
        menuData.Opciones.forEach(item => {
          if (!item.SoloMovisat || (item.SoloMovisat && user.Interno)) {
            menuOptions.push(
              {
                id: item.Id,
                text: "<div style='color: lightgray;'>" + MainComponent.translate(item.TextoId) + '</div>',
                parentid: item.PadreId > 0 ? item.PadreId : -1,
                subMenuWidth: '230px',
                value: item.AccionId,
                textId: item.TextoId
              }
            );
          }
        });
      }

      // Pongo en negrita las opciones que tienen asociada alguna acción
      for (let i = 0, j = 0; i < menuData.Opciones.length; i++, j++) {
        if (!menuData.Opciones[i].SoloMovisat || (menuData.Opciones[i].SoloMovisat && user.Interno)) {
          const disable = menuData.Opciones[i].AccionId.length < 1;
          if (!disable) {
            menuOptions[j].text = menuOptions[j].text.replace('lightgray', 'black');
          }
          while (menuData.Opciones[i].PadreId > 0 && !disable) {
            const parentItem = menuData.Opciones.find(f => f.Id === menuData.Opciones[i].PadreId);
            if (parentItem) {
              const mOpt = menuOptions.find(f => f.id === parentItem.Id);
              if (mOpt) {
                mOpt.text = mOpt.text.replace('lightgray', 'black');
              }
              menuData.Opciones[i] = parentItem;
            } else {
              break;
            }
          }
        } else {
          j--;
        }
      }
      // Muestro el menú
      const source = {
        datatype: "json",
        datafields: [
          { name: 'id' },
          { name: 'parentid' },
          { name: 'text' },
          { name: 'subMenuWidth' },
          { name: 'value', map: 'value' }
        ],
        id: 'id',
        localdata: menuOptions
      };
      const dataAdapter = new jqx.dataAdapter(source);
      dataAdapter.dataBind();
      const records = dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);
      this.menu.source(records);
      this.menuOptions = menuOptions;
      this.checkMenuOption(menuOptions);
    }, 0);

  }

  public async viewAmbito(): Promise<boolean> {
    const res = await this.configService.getEmpApp('ambito-actividad', null);
    const ambito: AmbitoActividadModel = JSON.parse(res);
    return ambito && ambito.zonas && ambito.zonas.length > 0;
  }

  // Si no existe ámbito de actividad y no exsite marco geografico no se muestra el menu
  public async checkMenuOption(listOptions: any[]) {
    if (!await this.viewAmbito()) {
      // Estas son las opciones que están activas cuando no se ha definido ámbito
      const optEnabled: string[] = [
        'Configuracion',
        'Cartografia',
        'Marco_geografico',
        'Ambito_actividad',
        'Recursos',
        'Edificios_e_instalaciones',
        'Edificios_instalaciones'
      ]
      // Si no existe ámbito de actividad sólo se muestran las opciones de configuracion
      listOptions.forEach(element => {
        if (!optEnabled.find(o => o === element.textId)) {
          this.menu.disable(element.id, true);
        }
      });
    }
  }

  onItemClick(event: any) {
    let component, component2;
    if (event.args.attributes['item-value'].value) {
      /*
        En caso de que se clicke en un elemento que tenga accion cierra el menu
        se hace aqui para que en el movil funcione correctamente
      */
      this.menu.elementRef.nativeElement.children[0].children[0].childNodes.forEach(element => {
        this.menu.closeItem(element.id);
      });

      switch (event.args.attributes['item-value'].value) {
        case 'CARTOGRAFIA_HERRAMIENTAS_MEDIR':
          if (MeasureComponent && MeasureComponent._this && MeasureComponent._this.form) {
            MeasureComponent._this.form.bringToFront();
            MeasureComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(MeasureComponent);
            component.instance.init(component);
          }
          break;

        case 'CARTOGRAFIA_POLIGONOS':
          if (ZonesComponent && ZonesComponent._this && ZonesComponent._this.form) {
            ZonesComponent._this.form.bringToFront();
            ZonesComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ZonesComponent);
            component.instance.init(component, true);
          }
          break;

        case 'CARTOGRAFIA_NUEVA_VISTA':
          MainComponent.createMap(null, null, true, null);
          break;

        case 'CARTOGRAFIA_HERRAMIENTAS_DEF_CUADRICULAS':
          if (CuadriculasComponent && CuadriculasComponent._this && CuadriculasComponent._this.form) {
            CuadriculasComponent._this.form.bringToFront();
            CuadriculasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(CuadriculasComponent);
            component.instance.init(component);
          }
          break;

        case 'CARTOGRAFIA_ZONAS':
          if (ZonesComponent && ZonesComponent._this && ZonesComponent._this.form) {
            ZonesComponent._this.form.bringToFront();
            ZonesComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ZonesComponent);
            component.instance.init(component, false, null, false);
          }
          break;

        case 'CARTOGRAFIA_CASA':
          this.centrarCasa();
          break;

        case 'CATALOGO_VEHICULOS':
          if (MovilesCatalogComponent && MovilesCatalogComponent._this && MovilesCatalogComponent._this.form) {
            MovilesCatalogComponent._this.form.bringToFront();
            MovilesCatalogComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(MovilesCatalogComponent);
            component.instance.init(component, false);
          }
          break;

        case 'MOVILES':
          MainComponent.getInstance().tabGestion.selectAt(MainComponent.MOVILES_TAB);
          break;

        case 'MOVILIDAD_FLOTA_SUBFLOTAS':
          if (SubflotasComponent && SubflotasComponent._this && SubflotasComponent._this.form) {
            SubflotasComponent._this.form.bringToFront();
            SubflotasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(SubflotasComponent);
            component.instance.init(component);
          }
          break;

        case 'MOVILIDAD_FLOTA_RECORRIDOS':
          document.getElementById('btnRecorrido').click();
          break;

        case 'MOVILIDAD_FLOTA_ITINERARIOS':
          if (ItinerariComponent && ItinerariComponent._this && ItinerariComponent._this.form) {
            ItinerariComponent._this.form.bringToFront();
            ItinerariComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ItinerariComponent);
            component.instance.init(component, 0);
          }
          break;

        case 'MOVILIDAD_MOVILES_CERCANOS':
          MainComponent.getInstance().tabGestion.selectAt(MainComponent.MOVILES_TAB);

          if (MovilesCercaComponent && MovilesCercaComponent._this && MovilesCercaComponent._this.form) {
            MovilesCercaComponent._this.form.bringToFront();
            MovilesCercaComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(MovilesCercaComponent);
            component.instance.init(component, 0);
          }
          break;

        case 'MOVILIDAD_ALARMAS_GEO':
          if (AlarmsgeoComponent && AlarmsgeoComponent._this && AlarmsgeoComponent._this.form) {
            AlarmsgeoComponent._this.form.bringToFront();
            AlarmsgeoComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(AlarmsgeoComponent);
            component.instance.init(component);
          }
          break;

        case 'INFOR_LISTADO_IDENTIF_CERRADURAS':
          MainComponent.createTabInfo('INFOR_LISTADO_IDENTIF_CERRADURAS', true, true);
          break;

        case 'INFOR_LISTADO_ALARMAS_CERRADURAS':
          MainComponent.createTabInfo('INFOR_LISTADO_ALARMAS_CERRADURAS', true, true);
          break;

        case 'CATALOGO_ELEMENTOS':
          if (ElementsCatalogComponent && ElementsCatalogComponent._this && ElementsCatalogComponent._this.form) {
            ElementsCatalogComponent._this.form.bringToFront();
            ElementsCatalogComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ElementsCatalogComponent);
            component.instance.init(component);
          }
          break;

        case 'ELEMENTOS':
          MainComponent.getInstance().tabGestion.selectAt(MainComponent.ELEMENTOS_TAB);
          break;

        case 'PUNTOS_UBICACION':
          MainComponent.getInstance().setFormPuVisible(true);
          break;

        case 'IA_ESTUDIO_ACTUAL':
          MainComponent.getInstance().setFormIaVisible(true);
          MainComponent.getInstance().rightContainer.clear();
          // Creo los componentes IA
          component = this.menuContainer.createComponent(IaElementsComponent);
          component.instance.init(component);
          // El grid de elementos...
          MainComponent.getInstance().gridElemIaContainer.clear();
          component2 = MainComponent.getInstance().gridElemIaContainer.createComponent(IaGridComponent);
          component2.instance.init(component2);
          break;

        case 'CONFIG_CARTOGRAFIA_MARCO_GEO':
          if (MarcoComponent && MarcoComponent._this && MarcoComponent._this.form) {
            MarcoComponent._this.form.bringToFront();
            MarcoComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(MarcoComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_CARTOGRAFIA_CASA':
          if (CasaComponent && CasaComponent._this && CasaComponent._this.form) {
            CasaComponent._this.form.bringToFront();
            CasaComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(CasaComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_CARTOGRAFIA_AMBITO_ACTIVIDAD':
          if (AmbitoComponent && AmbitoComponent._this && AmbitoComponent._this.form) {
            AmbitoComponent._this.form.bringToFront();
            AmbitoComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(AmbitoComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_CARTOGRAFIA_PROV_CARTO':
          if (CartoSelectComponent && CartoSelectComponent._this && CartoSelectComponent._this.form) {
            CartoSelectComponent._this.form.bringToFront();
            CartoSelectComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(CartoSelectComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_MOVILIDAD_SUBFLOTAS':
          if (SubflotasComponent && SubflotasComponent._this && SubflotasComponent._this.form) {
            SubflotasComponent._this.form.bringToFront();
            SubflotasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(SubflotasComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_MOVILES_IA':
          if (MovilesCatalogComponent && MovilesCatalogComponent._this && MovilesCatalogComponent._this.form) {
            MovilesCatalogComponent._this.form.bringToFront();
            MovilesCatalogComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(MovilesCatalogComponent);
            component.instance.init(component, true);
          }
          break;

        case 'CONFIG_ELEMENTOS_IA':
          if (ElementsCatalogComponent && ElementsCatalogComponent._this && ElementsCatalogComponent._this.form) {
            ElementsCatalogComponent._this.form.bringToFront();
            ElementsCatalogComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ElementsCatalogComponent);
            component.instance.init(component, true);
          }
          break;

        case 'LISTADO_MOVILIDAD':
          if (ListadoMovilidadComponent && ListadoMovilidadComponent._this && ListadoMovilidadComponent._this.form) {
            ListadoMovilidadComponent._this.form.bringToFront();
            ListadoMovilidadComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoMovilidadComponent);
            component.instance.init(component);
          }
          break;

        case 'LISTADO_VOLUMETRICOS':
          if (ListadoVolumComponent && ListadoVolumComponent._this && ListadoVolumComponent._this.form) {
            ListadoVolumComponent._this.form.bringToFront();
            ListadoVolumComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoVolumComponent);
            component.instance.init(component);
          }
          break;

        case 'LISTADO_SENSORES':
          if (ListadoSensorComponent && ListadoSensorComponent._this && ListadoSensorComponent._this.form) {
            ListadoSensorComponent._this.form.bringToFront();
            ListadoSensorComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoSensorComponent);
            component.instance.init(component);
          }
          break;

        case 'LISTADO_ALARMAS_GEO':
          if (ListadoAlarmasComponent && ListadoAlarmasComponent._this && ListadoAlarmasComponent._this.form) {
            ListadoAlarmasComponent._this.form.bringToFront();
            ListadoAlarmasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoAlarmasComponent);
            component.instance.init(component);
          }
          break;

        case 'INFOR_LISTADO_CERRADURAS':
          if (ListadoCerraduraComponent && ListadoCerraduraComponent.instance && ListadoCerraduraComponent.instance.form) {
            ListadoCerraduraComponent.instance.form.bringToFront();
            ListadoCerraduraComponent.instance.form.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoCerraduraComponent);
            component.instance.init(component);
          }
          break;

        case 'INFOR_HIST_ENVIOS_CERRADURAS':
          MainComponent.createTabInfo('INFOR_HIST_ENVIOS_CERRADURAS', true, true);
          break;

        case 'CONFIG_CARTOGRAFIA_KML':
          if (KmlComponent && KmlComponent._this && KmlComponent._this.form) {
            KmlComponent._this.form.bringToFront();
            KmlComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(KmlComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_ELEMENTOS_SELEC':
          if (ElementsSelectComponent && ElementsSelectComponent._this && ElementsSelectComponent._this.form) {
            ElementsSelectComponent._this.form.bringToFront();
            ElementsSelectComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ElementsSelectComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_CALENDARIOS':
          if (CalendarComponent && CalendarComponent._this && CalendarComponent._this.form) {
            CalendarComponent._this.form.bringToFront();
            CalendarComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(CalendarComponent);
            component.instance.init(component);
          }
          break;

        case 'GESTION_INCIDENCIAS':
          if (GridIncidencesComponent && GridIncidencesComponent._this && GridIncidencesComponent._this.windowIncidences) {
            GridIncidencesComponent._this.windowIncidences.bringToFront();
            GridIncidencesComponent._this.windowIncidences.expand();
          } else {
            component = this.menuContainer.createComponent(GridIncidencesComponent);
            component.instance.init(component);
          }
          break;

        case 'BDT_SINCRONIZAR':
          this.loader.open();
          setTimeout(async () => {
            this.bdtService.sincronizaBDT();
            this.loader.close();
          }, 2000);

          break;

        case 'DOC_MANUAL_USUARIO':
          let urlFile = '';
          //seleccionar url según app
          switch (MainComponent.getInstance().ssoTicket.Aplicacion.Id) {
            case App.ECOEVOLUTION:
              urlFile = 'https://docs.google.com/document/d/1tcb9uHU06AWTnd5wabIe2oSca1mC1GdO/edit?usp=sharing&ouid=104449151105465490839&rtpof=true&sd=true';
              break;
            case App.ECOEVOLUTION_DEV:
              urlFile = 'https://docs.google.com/document/d/1tcb9uHU06AWTnd5wabIe2oSca1mC1GdO/edit?usp=sharing&ouid=104449151105465490839&rtpof=true&sd=true';
              break;
            case App.ECOSATLITE:
              urlFile = 'https://docs.google.com/document/d/1s8EHYwiuAbRWNSiBnK7W5ucwvNcU1mV_/edit?usp=sharing&ouid=104449151105465490839&rtpof=true&sd=true';
              break;
            case App.GESCONSAT:
              urlFile = 'https://docs.google.com/document/d/16Y0gEJl_b65bxyC4SvWXY05dsefiW6Uo/edit?usp=sharing&ouid=104449151105465490839&rtpof=true&sd=true';
              break;
            //ecosat por defecto
            default:
              urlFile = 'https://docs.google.com/document/d/1ti89RHeiMbBRDhA8vP5ecLJbfCVzngvR/edit?usp=sharing&ouid=104449151105465490839&rtpof=true&sd=true';
              break;
          }
          //abrir documento en una nueva ventana
          window.open(urlFile);
          break;

        case 'ANOTACIONES':
          if (NotasComponent && NotasComponent._this && NotasComponent._this.form) {
            NotasComponent._this.form.bringToFront();
            NotasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(NotasComponent);
            component.instance.init(component);
          }
          break;

        case 'AUDITORIA_ACCIONES':
          if (AccionesComponent && AccionesComponent._this && AccionesComponent._this.form) {
            AccionesComponent._this.form.bringToFront();
            AccionesComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(AccionesComponent);
            component.instance.init(component);
          }
          break;

        case 'AUDITORIA_INFORMES':
          if (InformesComponent && InformesComponent._this && InformesComponent._this.form) {
            InformesComponent._this.form.bringToFront();
            InformesComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(InformesComponent);
            component.instance.init(component);
          }
          break;

        case 'GESTION_CIUDADANOS':
        case 'ELEM_CIUDADANOS':
          MainComponent.getInstance().setFormCiudadanoVisible(true);
          break;

        case 'CONFIG_CERRADURAS_CONFIGURAR':
          if (ConfiguracionCerradurasComponent && ConfiguracionCerradurasComponent._this && ConfiguracionCerradurasComponent._this.form) {
            ConfiguracionCerradurasComponent._this.form.bringToFront();
            ConfiguracionCerradurasComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(ConfiguracionCerradurasComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_CERRADURAS_CALENDARIOS':
          if (AssociateLockComponent && AssociateLockComponent._this && AssociateLockComponent._this.formAssociate) {
            AssociateLockComponent._this.formAssociate.bringToFront();
            AssociateLockComponent._this.formAssociate.expand();
          } else {
            component = this.menuContainer.createComponent(AssociateLockComponent);
            component.instance.init(component);
          }
          break;

        case 'CONFIG_ADMINISTRADOR_JOBS':
          if (ListadoTareasComponent && ListadoTareasComponent._this && ListadoTareasComponent._this.windowJob) {
            ListadoTareasComponent._this.windowJob.bringToFront();
            ListadoTareasComponent._this.windowJob.expand();
          } else {
            component = this.menuContainer.createComponent(ListadoTareasComponent);
            component.instance.init(component);
          }
          break;

        case 'REC_TARJ_CIUD':
          if (TarjetasCiudadanosComponent && TarjetasCiudadanosComponent._this && TarjetasCiudadanosComponent._this.form) {
            TarjetasCiudadanosComponent._this.form.bringToFront();
            TarjetasCiudadanosComponent._this.form.expand();
          } else {
            component = this.menuContainer.createComponent(TarjetasCiudadanosComponent);
            component.instance.init(component);
          }
          break
        default:
          console.log(event.args.attributes['item-value'].value);
          break;
      }
    }
  }

  async centrarCasa() {
    const res = await this.configService.getEmpApp('casa', null);
    if (res) {
      const casa: CasaModel = JSON.parse(res);
      MainComponent.getActiveMap().setZoom(casa.zoom);
      MainComponent.getActiveMap().setCenter(casa.posicion);
    }
  }

  tongleMenu(manual: boolean) {
    this.manualTongle = manual;
    // Cuando se minimiza el menú muevo hacia la derecha el texto del mapa0 para que no se superponga
    const map0: any = document.getElementById('mapaPrincipal');
    if (!this.minimized) {
      map0.style.cssText = 'float: left; margin-left: 40px;';
    } else {
      map0.style.cssText = 'float: left;';
    }
    if (window.innerWidth < MIN_DISPLAY_WIDTH) {
      // Quito los textos del menú
      this.ponTextosMenu(false);
    } else {
      // Vuelvo a poner los textos del menú
      this.ponTextosMenu(true);
    }
    this.minimized = !this.minimized;
    window.dispatchEvent(new Event('resize'));
  }

  ponTextosMenu(conTextos: boolean) {
    // if (!conTextos) {
    //   if (this.menuConTextos) {
    //     const listElements = this.menu.elementRef.nativeElement.firstElementChild.children[0].children;
    //     for (let i = 0; i < listElements.length; i++) {
    //       this.menuSave.push(listElements[i].innerHTML);
    //       listElements[i].innerHTML = listElements[i].innerHTML.substring(0, listElements[i].innerHTML.lastIndexOf('">')) + '</span>';
    //     }
    //   }
    // } else {
    //   if (!this.menuConTextos) {
    //     const allLiElements = this.menu.elementRef.nativeElement.firstElementChild.children[0].children;
    //     for (let i = 0; i < allLiElements.length; i++) {
    //       allLiElements[i].innerHTML = this.menuSave[i];
    //     }
    //   }
    // }
    this.menuConTextos = conTextos;
  }


  // Initialized(event) {

  //   console.log(event);
  //   if (event) {

  //     console.log(event);
  //   }

  // }

}
