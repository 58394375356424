import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';

import { Guid } from 'guid-typescript';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import {
  MapComponent,
  MapLatLng,
  MapMarker,
  MapCircle,
  MapPolygonPoint,
} from 'src/app/imports';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { PuService } from 'src/app/services/pu/pu.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { PuModel } from 'src/app/services/pu/models/pu.model';
import { TagModel } from 'src/app/services/elements/models/tag.model';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';

import { ElementsComponent } from '../elements.component';
import { ElementsCatalogComponent } from '../elements-catalog/elements-catalog.component';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ElementsEditInformacionComponent } from './elements-edit-informacion/elements-edit-informacion.component';
import { ElementsEditDetalleComponent } from './elements-edit-detalle/elements-edit-detalle.component';
import { ElementsEditPerifericosComponent } from './elements-edit-perifericos/elements-edit-perifericos.component';
import { ElementsEditInstalacionesComponent } from './elements-edit-instalaciones/elements-edit-instalaciones.component';
import { ElementsEditGestionComponent } from './elements-edit-gestion/elements-edit-gestion.component';
import { ElementsEditHistoricoCoordenadasComponent } from './elements-edit-historico-coordenadas/elements-edit-historico-coordenadas.component';

@Component({
  selector: 'app-elements-edit',
  templateUrl: './elements-edit.component.html',
  styleUrls: ['./elements-edit.component.css'],
})
export class ElementsEditComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('elemRibbon') elemRibbon;
  @ViewChild('component', { read: ViewContainerRef }) component;

  @ViewChild('tabInformacion') tabInformacion: ElementsEditInformacionComponent;
  @ViewChild('tabDetalle') tabDetalle: ElementsEditDetalleComponent;
  @ViewChild('tabPeriferico') tabPeriferico: ElementsEditPerifericosComponent;
  @ViewChild('tabInstalacion') tabInstalacion: ElementsEditInstalacionesComponent;
  @ViewChild('tabGestion') tabGestion: ElementsEditGestionComponent;
  @ViewChild('tabHistoricoCoordenadas') tabHistoricoCoordenadas: ElementsEditHistoricoCoordenadasComponent;

  public expanded = true;
  public closed = false;

  public canEdit = true;
  ejecutado: boolean = false;

  environment = environment;

  elemEdit: ElementoModel;
  crearMultiples = false;

  map: MapComponent;
  markerHistorico: MapMarker;

  // Recoge las medidas de la parte equivalente al mapa
  mapHeight;
  mapWidth;

  private componentRef = null;

  public static _this: ElementsEditComponent;
  public catEquipSelec: BdtCatalogoEquipamientoModel;

  private oldIcon: any = null;
  private save = false;

  // Subscripciones
  private subscriptionMapClick: any = null;
  private subscriptionCircleClick: any = null;
  private subscriptionPolygonClick: any = null;
  private subscriptionMarkerClick: any = null;
  private subscriptionOnMarkerDragEnd: any = null;
  showLoader: boolean = true;

  // Para traducir los textos del template
  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private puService: PuService,
    private ssoService: SsoService,
    private elemService: ElementsService,
    private cerraduraService: CerraduraService,
    private zonesService: ZonesService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
    ElementsEditComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.map = MainComponent.getInstance().getMap();
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, elemento: ElementoModel) {
    this.componentRef = componentRef;

    if (elemento) {
      try {
        this.elemService.getElement(elemento).then(
          (result: ElementoModel) => {
            this.elemEdit = result;

            this.elemEdit.Marker = elemento.Marker;
            this.elemEdit.UltFechaVolum = new Date(this.elemEdit.UltFechaVolum);
            this.elemEdit.FechaInstalacionVolum = new Date(this.elemEdit.FechaInstalacionVolum);

            let timeout = setTimeout(() => {
              if (this.map && this.elemEdit && this.elemEdit.Marker) {
                this.markerHistorico = this.map.addMarker({
                  dataModel: this.elemEdit.Marker.dataModel,
                  title: this.elemEdit.Marker.title,
                  content: this.elemEdit.Marker.content,
                  icon: this.elemEdit.Marker.icon,
                  zIndex: this.elemEdit.Marker.zIndex,
                  visible: true
                });
                clearTimeout(timeout);
              }
            }, 0)

            this.elemService.getInstalacionElemento(this.elemEdit).then(
              (result: any) => {
                this.elemEdit.instalacion = result;
              }
            )
            this.form.setTitle(this.translate('Edicion_elementos') + ': ' + this.elemEdit.Nombre);
          }

        );

      } catch (e) {

      }
    } else {
      this.elemEdit = new ElementoModel();
      this.elemEdit.Imagenes = [];
    }

    if (this.elemEdit && this.elemEdit.Id > 0 && this.elemEdit.Marker) {
      this.oldIcon = this.elemEdit.Marker.icon;
      this.crearMultiples = false;
    }
  }

  changeTab(event: any) {
    switch (event.args.selectedIndex) {
      case 0: // TabInformacion
        this.tabInformacion.initGridHistorico();
        break;
      case 1: // TabDetalle
        this.tabDetalle.initGridClasesAsociadas();
        break;
      case 2: // TabPeriferico
        break;
      case 3: // TabInstalacion
        this.tabInstalacion.initGridIncidenciasVolum();
        this.tabInstalacion.initGridIncidenciasTag();
        this.tabInstalacion.initGridIncidenciasEcolock();
        break;
      case 4: // TabGestion
        this.tabGestion.initGridOperRecientes();
        this.tabGestion.initGridHistIncidencias();
        break;
      case 5: // TabHistoricoCoordenadas
        this.tabHistoricoCoordenadas.initGridHistCoordenadas();
        break;
      default:
        break;
    }
  }

  updateEquipamiento(equip: any) {
    this.catEquipSelec = equip;
    this.elemEdit.Equipamiento = equip;
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Edicion_elementos'));

    //Recoge el div autogenerado del jqxRibbon y le establece el height
    let ribbonDiv = this.elemRibbon.elementRef.nativeElement.firstChild as HTMLElement;
    ribbonDiv.style.height = '100%';
    this.showLoader = false;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  closeWindow() {
    this.form.close();
  }

  // Cuando se visualiza el formulario
  onOpen(): void {
    if (this.elemEdit) {
      if(this.form){
        this.form.setTitle(this.translate('Edicion_elementos') + ': ' + this.elemEdit.Nombre);
      }

      if (!this.elemEdit.Tag) {
        this.elemEdit.Tag = new TagModel();
      }
      if (!this.elemEdit.IdCircuito) {
        this.elemEdit.IdCircuito = '';
      }
      switch (this.elemEdit.EstadoInstalVolum) {
        case 0:
          this.elemEdit.estadoInstallTexto = 'Sin instalar';
          break;
        case 1:
          this.elemEdit.estadoInstallTexto = 'Instalado pendiente lectura';
          break;
        case 2:
          this.elemEdit.estadoInstallTexto = 'Instalado correctamente';
          break;
        default:
          this.elemEdit.estadoInstallTexto = '';
      }
      // Si es un elemento que ya existe lo centro en el mapa
      if (this.elemEdit.Id > 0 && this.elemEdit.Marker) {
        this.onPosicionar();
      }
    }
  }

  // Esto es para que no se quede el desplegable abierto cuando se minimiza el formulario
  onCollapse(event: any) {
    this.expanded = false;
  }

  // al expandir el formulario
  onExpand(event: any) {
    if (this.subscriptionMapClick) {
      this.expanded = true;
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.map.setMousePointer('');
    }
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    this.closed = true;
    //Elimino el marcador del historico del mapa
    if (this.markerHistorico) {
      this.map.removeMarker(this.markerHistorico);
    }

    this.map.setMousePointer('');
    if (this.elemEdit.Marker) {
      if (this.elemEdit.Id < 1) {
        this.map.removeMarker(this.elemEdit.Marker);
      } else {
        if (!this.save && this.oldIcon) {
          this.elemEdit.Marker.setIcon(this.oldIcon);
        }
      }
    }
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
    }
    if (this.subscriptionCircleClick) {
      this.subscriptionCircleClick.unsubscribe();
    }
    if (this.subscriptionPolygonClick) {
      this.subscriptionPolygonClick.unsubscribe();
    }
    if (this.subscriptionMarkerClick) {
      this.subscriptionMarkerClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerDragEnd) {
      this.subscriptionOnMarkerDragEnd.unsubscribe();
    }
    this.map.setMousePointer('');
    if (ElementsCatalogComponent._this) {
      ElementsCatalogComponent._this.form.expand();
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onCreacionMultiple(event: any) {
    this.crearMultiples = event.target.checked;
    if (!event.target.checked) {
      const t = setTimeout(() => {
        clearTimeout(t);
      }, 500);
      this.elemEdit.Lat = this.elemEdit.Lng = 0;
    }
  }

  // Guardar los cambios
  async onGuardar(event: any): Promise<void> {
    let numReg = 0;
    ElementsComponent.getInstance().elementList.forEach((elem) => {
      if (elem.Equipamiento.Id === this.catEquipSelec.Equipamiento.Id && !elem.FechaBaja) {
        numReg++;
      }
    });
    // Si las unidades permitidas son 0 no hay límite de elementos
    if (this.catEquipSelec.Unidades > 0 && this.elemEdit.Id < 1 && numReg > 0 && numReg >= this.catEquipSelec.Unidades) {
      MainComponent.getInstance().showError('ATENCION', 'Superado_max_elementos', 2000);
      return;
    }
    if (this.elemEdit.Equipamiento === null) {
      MainComponent.showWarning('ATENCION', 'Seleccione_equipamiento', 2000);
      return;
    }
    if (this.elemEdit.Nombre.length < 1 && !this.crearMultiples) {
      MainComponent.showWarning('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (this.elemEdit.Lat === 0 && this.elemEdit.Lng === 0) {
      MainComponent.getInstance().showWarning(
        'ATENCION',
        'Seleccione_punto_cartografia',
        2000
      );
      return;
    }
    if (this.elemEdit.Id > 0) {
      // Si se trata de una modificación compruebo que el modelo del punto de
      // ubicación coincida con el del elemento
      const pu = await this.puService.getPuntoUbicacionByElemento(
        this.elemEdit.Id
      );
      if (
        pu &&
        pu.Equipamiento.IdModelo !== this.elemEdit.Equipamiento.IdModelo
      ) {
        MainComponent.getInstance().showWarning(
          'ATENCION',
          'PU_distinto_modelo',
          2000
        );
        this.form.close();
        return;
      }
    }
    // Si es un elemento nuevo creo el GUID
    if (this.elemEdit.Guid.length < 1) {
      this.elemEdit.Guid = Guid.create().toString();
    }
    this.elemEdit.Empresa = this.ssoService.getTicket().Empresa.IdGestion;
    this.elemEdit.IdEquipamiento = this.elemEdit.Equipamiento.Id;
    if ((await this.elemService.saveElemento(this.elemEdit)) !== null) {
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );

      if (this.elemEdit.cerradura) {
        let asociacion: any = {
          "idCerradura": this.elemEdit.cerradura.id,
          "idElemento": this.elemEdit.Id,
          "fecha": new Date()
        };

        this.cerraduraService.associateCerraduraToElement(asociacion);
      }

      this.save = true;
      if (!this.crearMultiples) {
        this.form.close();
      }
    } else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }

  // Posicionar el componente sobre la cartografía
  private onPosicionar(): void {
    if (this.elemEdit.Id < 1) {
      this.form.collapse();
      // Cambio el puntero del ratón sobre el mapa
      this.map.setMousePointer('assets/images/posicionar.png');
      if (!this.subscriptionMapClick) {
        this.subscriptionMapClick = this.subscribeOnMapClick();
      }
      if (!this.subscriptionMarkerClick) {
        this.subscriptionMarkerClick = this.subscribeOnMarkerClick();
      }
      if (!this.subscriptionCircleClick) {
        this.subscriptionCircleClick = this.map.subscribeOnCircleClick(
          this,
          (_this: any, circle: MapCircle) => {
            this.map.onMapClick(circle.clickPoint);
          }
        );
      }
      if (!this.subscriptionPolygonClick) {
        this.subscriptionPolygonClick = this.map.subscribeOnPolygonClick(
          this,
          (_this: any, polygon: MapPolygonPoint) => {
            this.map.onMapClick(polygon.clickPoint);
          }
        );
      }
      if (!this.subscriptionOnMarkerDragEnd) {
        this.subscriptionOnMarkerDragEnd = this.map.subscribeOnMarkerDragEnd(
          this,
          (_this: any, marker: MapMarker) => {
            if (
              MainComponent.getInstance().controlAmbitoActividad(
                marker.position
              )
            ) {
              this.elemEdit.Lat = marker.position.lat;
              this.elemEdit.Lng = marker.position.lng;
            } else {
              if (this.elemEdit.Lat && this.elemEdit.Lng) {
                MainComponent.getInstance().showError(
                  'ATENCION',
                  'Fuera_ambito',
                  2000
                );
                marker.setPosition(
                  new MapLatLng(this.elemEdit.Lat, this.elemEdit.Lng)
                );
              }
            }
          }
        );
      }
    } else {
      this.map.setCenter(this.elemEdit.Marker.position);
      this.elemEdit.Marker.setZIndex(999);
      if (this.map.zoom < 18) {
        this.map.setZoom(18);
      }
      this.elemEdit.Marker.animate(2850);
    }
  }

  // Cada vez que se pincha sobre la cartogafía
  subscribeOnMapClick(): any {
    return this.map.subscribeOnMapClick(
      this,
      async (_this: any, position: MapLatLng) => {
        if (this.elemEdit.Id < 1) {
          // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón
          // const point = this.map.latLngToScreenPoint(position);
          // point.x += 16; // El icono es de 32x32
          // point.y += 16;
          // const newPosition = this.map.screenPointToLatLng(point);
          const newPosition = position;
          if (
            !MainComponent.getInstance().controlAmbitoActividad(newPosition)
          ) {
            MainComponent.getInstance().showError(
              'ATENCION',
              'Fuera_ambito',
              2000
            );
            return;
          } else {
            this.zonesService.getDireccion(newPosition.lat, newPosition.lng).then(
              (result: any) => {
                this.elemEdit.Calle = result.Street;
                this.elemEdit.Poblacion = result.District;
                this.elemEdit.CodigoPostal = result.PostalCode;
                this.elemEdit.Provincia = result.AdministrativeArea2;
                this.elemEdit.Pais = result.Country;
                this.elemEdit.NumeroCalle = result.StreetNumber;
                this.elemEdit.Municipio = result.Locality;
                this.elemEdit.ComunidadAutonoma = result.AdministrativeArea1;
              }
            );
          }
          if (this.elemEdit.Marker) {
            this.map.removeMarker(this.elemEdit.Marker);
          }
          this.elemEdit.Lat = newPosition.lat;
          this.elemEdit.Lng = newPosition.lng;
          this.elemEdit.IdPU = 0;
          this.elemEdit.Marker = this.map.addMarker({
            dataModel: this.elemEdit,
            title: this.elemEdit.Nombre,
            content:
              '<b>' +
              this.elemEdit.Nombre +
              '</b><hr>' +
              this.elemEdit.Equipamiento.Elemento.Nombre +
              '<br>' +
              this.elemEdit.Equipamiento.Marca.Nombre +
              '<br>' +
              this.elemEdit.Equipamiento.Modelo.Nombre,
            position: new MapLatLng(this.elemEdit.Lat, this.elemEdit.Lng),
            icon:
              !this.elemService.elemGenericos ||
                (this.elemEdit.Equipamiento.Icono &&
                  this.elemEdit.Equipamiento.Icono.length > 50)
                ? 'data:image/png;base64,' + this.elemEdit.Equipamiento.Icono
                : 'assets/images/elemento.png',
            zIndex: 999,
            drag: true,
            visible: true,
          });
        }
        if (!this.crearMultiples) {
          this.form.expand();
        } else {
          // Inicializo los identificadores para que se crée un nuevo elemento
          this.elemEdit.Id = 0;
          this.elemEdit.Guid = '';
          this.elemEdit.Nombre = ''; // El nombre se asigna después de almacenarlo ya que contendrá el id
          if (this.elemEdit.Marker) {
            this.map.removeMarker(this.elemEdit.Marker);
          }
          this.elemEdit.Marker = null;
          this.onGuardar(null);
        }
      }
    );
  }

  // Cada vez que se pincha sobre un marcador
  subscribeOnMarkerClick(): any {
    return this.map.subscribeOnMarkerClick(this, (_this, marker: MapMarker) => {
      // Compruebo que se trata de un punto de ubicación
      if (marker.dataModel.hasOwnProperty('IdElemento')) {
        if (this.elemEdit.Id < 1) {
          if (this.elemEdit.Marker) {
            this.map.removeMarker(this.elemEdit.Marker);
          }
          const pu: PuModel = marker.dataModel;
          if (
            pu &&
            pu.Equipamiento.IdModelo !== this.elemEdit.Equipamiento.IdModelo
          ) {
            MainComponent.getInstance().showWarning(
              'ATENCION',
              'PU_distinto_modelo',
              2000
            );
            return;
          }
          if (pu.IdElemento > 0) {
            MainComponent.getInstance().showWarning(
              'ATENCION',
              'PU_ya_tiene_elemento',
              2000
            );
            return;
          }
          this.elemEdit.IdPU = pu.Id;
          this.elemEdit.Lat = marker.position.lat;
          this.elemEdit.Lng = marker.position.lng;
          this.elemEdit.Marker = this.map.addMarker({
            dataModel: this.elemEdit,
            title: this.elemEdit.Nombre,
            content:
              '<b>' +
              this.elemEdit.Nombre +
              '</b><hr>' +
              this.elemEdit.Equipamiento.Elemento.Nombre +
              '<br>' +
              this.elemEdit.Equipamiento.Marca.Nombre +
              '<br>' +
              this.elemEdit.Equipamiento.Modelo.Nombre,
            position: new MapLatLng(this.elemEdit.Lat, this.elemEdit.Lng),
            icon:
              !this.elemService.elemGenericos ||
                (this.elemEdit.Equipamiento.Icono &&
                  this.elemEdit.Equipamiento.Icono.length > 50)
                ? 'data:image/png;base64,' + this.elemEdit.Equipamiento.Icono
                : 'assets/images/elemento.png',
            zIndex: 999,
            drag: true,
            visible: true,
          });
        }
        this.subscriptionMarkerClick.unsubscribe();
        this.subscriptionMarkerClick = null;
        this.map.setMousePointer('');
        this.form.expand();
      }
    });
  }
}
