<!-- Loader -->
<div class="loader-container" *ngIf="showLoader">
    <div class="loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
    [height]="mapHeight" [autoOpen]="true" [resizable]="true" [showCloseButton]="true" [showCollapseButton]="true"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Zonas_cartograficas')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden; ">
        <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
            (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()" (eventBuscador)="onBuscar()"
            (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
        </app-header>
        <div style="float:left; width: 100%; height: 100%;">
            <jqxGrid #gridZonas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 80px)'" [source]="dataAdapter"
                [localization]="langGrid" [filterable]="true" [columns]="columns" [showrowlines]="false"
                [columnsresize]="true" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25"
                [columnsheight]="20" [editable]="true" [groupable]="true" [selectionmode]="'checkbox'"
                (onRowselect)="onChecked($event)" (onRowunselect)="onUnselect($event)"
                (onBindingcomplete)="onBindingComplete()" (onRowdoubleclick)="onEditarZona($event)">
            </jqxGrid>
            <div style="float: left; margin-top: 10px">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Ver')"
                    (onClick)="onVer()">
                </jqxButton>
                <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
                  [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
                  [value]="translate('Salir')">
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditZona">
    <jqxWindow #formEditZona [position]="getFormPos(formEditZona)" [width]="900" [height]="350" [zIndex]="999"
        [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
        (onClose)="onCloseEditZona()" (onOpen)="onOpenEditZona()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_zonas_cartograficas')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div class="container">
                <div class="columnForm" style="margin-right: 2px;">
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoMaster">
                        {{translate('Tipo_zona')}}
                        <jqxDropDownList #cbTiposMaster [width]="310" [height]="25" [autoDropDownHeight]="true"
                            (onSelect)="onChangeTipoMaster($event)">
                        </jqxDropDownList>
                    </div>
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoZonas">
                        {{translate('Edificios_instalaciones')}}
                        <jqxDropDownList #cbTiposZonas [width]="310" [height]="25" [selectedIndex]="0"
                            (onSelect)="onChangeTipoZona($event)">
                        </jqxDropDownList>
                    </div>
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenGeometria">
                        {{translate('Geometria')}}
                        <jqxDropDownList #cbTiposGeo [width]="310" [height]="25" [selectedIndex]="0"
                            (onSelect)="onChangeTipoGeo($event)">
                        </jqxDropDownList>
                    </div>
                    <div class="input-buscar" style="margin-top: 5px; clear: both;" [hidden]="hiddenInputSearch">
                        <div>
                            {{translate('Buscar')}}
                        </div>
                        <div class="autocomplete-container">
                            <form class="form">
                                <mat-form-field style="width: 310px !important;" appearance="fill">
                                    <input class="input-search" type="text" matInput [formControl]="myControl"
                                        [matAutocomplete]="auto" (input)="onChangeInput($event)">
                                    <mat-autocomplete #auto="matAutocomplete"
                                        (optionActivated)="onOptionActivated($event)"
                                        (optionSelected)="seleccionarArea($event)" [displayWith]=" displayFn">
                                        <mat-option *ngFor="let area of filtrarAreas(area)" [value]="area">
                                            {{ area }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Nombre')}}
                    </div>
                    <div style="float:left; clear:both;">
                        <jqxInput type="text" class="input-search" #nombre [(ngModel)]="zonaEdit.Nombre" [width]="310"
                            [height]="25" [disabled]="!canEdit" (keyup)="onKey(nombre.value)"> </jqxInput>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Observaciones')}}
                    </div>
                    <div style="float:left; clear: both;">
                        <jqxInput [(ngModel)]="zonaEdit.Observaciones" [width]="310" [height]="25"
                            [disabled]="!canEdit">
                        </jqxInput>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;width: 100%;" [hidden]="hiddenELementContainer">
                        <div>
                            {{translate('Elemento_seleccionado')}}
                        </div>
                        <div *ngIf="!infoElement" class="element-container">
                            {{translate('Selecciona_elemento')}}
                        </div>
                        <div class="element-container">
                            <div>
                                <img [src]="infoMarker">
                            </div>
                            <div class="column">
                                <div>
                                    {{infoElement}}
                                </div>
                                <div>
                                    {{infoNombre}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="zonaEdit.TipoGeo==0" [hidden]="hiddenRadius">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Radio_accion_metros')}}
                        </div>
                        <div style="float:left; clear: both;">
                            <jqxNumberInput [(ngModel)]="zonaEdit.Radio" [width]="80" [height]="25"
                                [spinButtons]="false" [min]="0" [max]=5000000 [inputMode]="'simple'" [decimalDigits]="0"
                                [disabled]="!canEdit" (onChange)="onChangeRadius()">
                            </jqxNumberInput>
                        </div>
                    </div>
                    <div *ngIf="zonaEdit.TipoGeo==1">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Superficie')}}
                        </div>
                        <div style="float:left; clear: both;">
                            {{superficie}} m<sup>2</sup>.
                        </div>
                    </div>
                </div>
                <div class="columnGrid">
                    <div>
                        {{translate('Entradas_salidas')}}
                    </div>
                    <!-- <app-entradas-salidas></app-entradas-salidas> -->
                    <app-entrada-salida [editable]="editEntradas"></app-entrada-salida>
                </div>
                <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                    <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                        (onClick)="onGuardarZona()" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                        [imgSrc]="'/assets/images/cancel.png'" [imgPosition]="'left'" [value]="translate('CANCELAR')"
                        (onClick)="onCancelar($event)">
                    </jqxButton>
                    <jqxButton style="float: left; margin-left: 3px" [width]="100" [height]="26"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                        [imgSrc]='"/assets/images/zonas.png"' [imgPosition]="'left'" [value]="translate('Dibujar')"
                        (onClick)="onDibujarZona($event)">
                    </jqxButton>
                </div>
            </div>
        </div>
    </jqxWindow>
</div>
