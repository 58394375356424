<div class="form" style="position: absolute; z-index: 1000; display: flex; height: 100%; width: 100%;">
  <div style="overflow-x: hidden; width: 100%;">
    <div style="float: left; margin-top: 2px; height:95%; width: '100%';">
      <div>
        <app-header #header [exportar]="true" [periodo]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
          [noLibre]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
          (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
        </app-header>
      </div>
      <div style="height: calc(100% - 40px); margin-top: 5px;">
        <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapter" [columnsresize]="true"
          [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns" [altrows]="true"
          [filterable]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
          [columnsheight]="20" [localization]="langGrid" [groupable]="true" [groupsrenderer]="groupsrenderer"
          (onGroupschanged)="resizeColumns()" (onFilter)="onfilter($event)">
        </jqxGrid>
      </div>
    </div>
  </div>
</div>