<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #formAssociate [theme]="theme" [position]="getFormPos(formAssociate, 0)" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Asociar_cerradura')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: row; gap: 15px;">
      <div style="width:50%">
        <div>
          {{translate('Calendarios')}}
        </div>
        <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'calc(100% - 20px)'" [source]="dataAdapter"
          [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columnCalendar"
          [showrowlines]="false" [showcolumnlines]="true" [showaggregates]="true" [pageable]="false" [sortable]="true"
          [altrows]="true" [statusbarheight]="20" [showstatusbar]="true" [rowsheight]="20" [columnsheight]="20"
          [editable]="false" [columnsresize]="true" (onRowclick)="onRowClick($event)" [filter]="filter">
        </jqxGrid>
      </div>
      <div style="width:50%;">
        <div>
          {{translate('Cerradura')}}
        </div>
        <jqxGrid id="grid1" #myGridLock [theme]="theme" [width]="'100%'" [height]="'calc(45% - 20px)'"
          [source]="dataAdapterLock" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
          [columns]="columnLock" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
          [altrows]="true" [statusbarheight]="20" [showstatusbar]="true" [showaggregates]="true" [rowsheight]="20"
          [columnsheight]="20" [editable]="false" [selectionmode]="'checkbox'"
          (onRowselect)="onSelectV2($event, 'grid1')" (onRowunselect)="onUnSelect($event, 'grid1')">
        </jqxGrid>
        <div style="display: flex; flex-direction: column; margin-left: 42%; height: 10%;">
          <div>
            <jqxButton style="float:left; clear: both; margin-right: 6px;" [width]='25' [height]='26'
              [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"'
              [imgSrc]='"/assets/images/arriba.png"' (onClick)="onMasClick($event)">
            </jqxButton>
            <jqxButton style="gap: 5px;" [width]='25' [height]='26' [textImageRelation]='"imageBeforeText"'
              [textPosition]='"right"' [imgPosition]='"left"' [imgSrc]='"/assets/images/abajo.png"'
              (onClick)="onMenosClick($event)">
            </jqxButton>
          </div>
        </div>
        <div>
          {{translate('Cerraduras_disponible')}}
        </div>
        <jqxGrid id="grid2" #myGridLockFree [theme]="theme" [width]="'100%'" [height]="'calc(45% - 20px)'"
          [source]="dataAdapterLockFree" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
          [columns]="columnLock" [showrowlines]="false" [showaggregates]="true" [showcolumnlines]="true"
          [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
          [rowsheight]="20" [columnsheight]="20" [editable]="false" [selectionmode]="'checkbox'"
          (onRowselect)="onSelectV2($event, 'grid2')" (onRowunselect)="onUnSelect($event, 'grid2')">
        </jqxGrid>
      </div>
    </div>
  </div>
</jqxWindow>
